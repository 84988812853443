import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import ColorThief from 'colorthief';

function Card({ imageSrc, text, supportText, identificator, optionName, votePick }) {
  const [bgColorClass, setBgColorClass] = useState('bg-gray-500'); // Default Tailwind CSS background color class
  const [textColorClass,setTextColorClass] = useState('text-black');

  useEffect(() => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = imageSrc;

    img.onload = () => {
      try {
        const colorThief = new ColorThief();
        const [r, g, b] = colorThief.getColor(img);
        const colorClass = rgbToTailwindClass(r, g, b);
        console.log(img.src);
        console.log(colorClass);
        setBgColorClass(colorClass[0]);
        setTextColorClass(colorClass[1])
        
      } catch (error) {
        console.error('Error extracting color:', error);
      }
    };
  }, [imageSrc]);

  // This function will need to be expanded to include all of the Tailwind color classes you want to support.
  const rgbToTailwindClass = (r, g, b) => {
    // This is a simplistic mapping, you may want to expand on this logic to get a more accurate color.
    console.log(r+" "+g+" "+b);
    if (r > 200 && g < 100 && b < 100) return ['bg-gray-300','text-gray-900'];//'bg-red-500';
    if (r < 100 && g > 200 && b < 100) return ['bg-gray-500','text-gray-200'];//'bg-green-500';
    if (r < 100 && g < 100 && b > 200) return ['bg-gray-700','text-gray-300'];//'bg-blue-500';
    // Add more conditions for different colors
    return ['bg-gray-500','text-gray-200']; // A default/fallback color
  };

  function chooseOption() {
    console.log("option");
    votePick(imageSrc);
  }

  let result = imageSrc.startsWith("https");
  return (
    <li key={identificator} className={`rounded-2xl ${bgColorClass} hover:bg-gray-600 px-8 py-10 mx-5 cursor-pointer`} type="button"
        id={identificator}
        name={optionName}
        onClick={chooseOption}>
      <img className="mx-auto h-48 w-48 rounded-md md:h-56 md:w-56 object-contain" src={result ? imageSrc : "./assets/logos/" + imageSrc} alt="" />
      <h3 className={`mt-6 text-base font-semibold leading-7 tracking-tight ${textColorClass}`}>{text}</h3>
      <p className={`text-sm leading-6 ${textColorClass}`}>{supportText}</p>
    </li>
  );
}

export default Card;


function Card2({ imageSrc, text, supportText,identificator, optionName, votePick }) {
  function chooseOption() {
    console.log("option");
    votePick(imageSrc);
  }

  return (
    <li key={identificator} className="rounded-2xl bg-gray-800 px-8 py-10" type="button"
      id={identificator}
      name={optionName}
      onClick={chooseOption}>
      <img className="mx-auto h-48 w-48 rounded-full md:h-56 md:w-56" src={imageSrc} alt="" />
      <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-white">{text}</h3>
      <p className="text-sm leading-6 text-gray-400">{supportText}</p>
    </li>

  )


}

function OldCard ({ imageSrc, text, identificator, optionName, votePick }) {

  function chooseOption() {
    console.log("option");
    votePick(imageSrc);
  }

  return (
    <>
      <div className="max-w-xl p-2 w-64 h-64 mx-auto bg-blue-600 hover:bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer shadow-md overflow-hidden flex flex-col justify-end saturate-200 rounded-full object-center"
        type="button"
        id={identificator}
        name={optionName}
        onClick={chooseOption}
      >
        <Transition
          show={true}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
        >
          <img
            src={imageSrc}
            alt="Card"
            className="w-full object-scale-down pt-9 self-center stroke-2 stroke-red-500 hover:filter-none"
          />
        </Transition>
      </div>
      <div className="flex justify-center">
        <div className="p-4">
          <p className="text-white text-center text-lg">{text}</p>
        </div>
      </div>
    </>
  );
};