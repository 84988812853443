import { useState,useEffect } from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'

export default function AdminItems() {

    const [data, setData] = useState(false)
    const fetchData = async () => {
        try {
            const response = await fetch("https://goskilling-node-wju6y.ondigitalocean.app/opinex/items/items");
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const jsonData = await response.json();
            setData(jsonData);
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle the error
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-5">
      {data ? data.map((item) => {
          let result = item.image.startsWith("https");
        return <li
          key={item.item_id}
          className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-gray-950 text-center shadow"
        >
          <div className="flex flex-1 flex-col p-8">
            <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full bg-slate-700" src={result?item.image:"./assets/logos/"+item.image} alt="" />
            <h3 className="mt-6 text-sm font-medium text-gray-100">{item.name}</h3>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <dt className="sr-only">Title</dt>
              <dd className="text-sm text-gray-300">{item.icon}</dd>
              <dt className="sr-only">Role</dt>
              <dd className="mt-3">
                <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {item.category_name}
                </span>
              </dd>
            </dl>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <a
                  href={`mailto:${item.email}`}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-200"
                >
                  <EnvelopeIcon className="h-5 w-5 text-gray-200" aria-hidden="true" />
                  Email
                </a>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <a
                  href={`tel:${item.telephone}`}
                  className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-200"
                >
                  <PhoneIcon className="h-5 w-5 text-gray-200" aria-hidden="true" />
                  Call
                </a>
              </div>
            </div>
          </div>
        </li>
      }): ""}
    </ul>
  )
}
