import { useState,useEffect } from "react";
import Interests from "./interests"
export default function Categories() {
    const [data,setData] = useState(false)
    const fetchData = async () => {
        try {
          const response = await fetch("https://goskilling-node-wju6y.ondigitalocean.app/opinex/categories/categories");
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const jsonData = await response.json();
          setData(jsonData);
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle the error
        }
      };   

      useEffect(() => {
        fetchData();
    }, [])


    return <div className="bg-gray-950 mx-auto min-h-screen py-12 flex justify-center items-center">
    <div>
      <h2 className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl pb-6">Categories</h2>
      <div className="max-w-2xl">
        {data ? <Interests interestsdata={data} /> : "loading..."}
      </div>
    </div>
  </div>
  
}