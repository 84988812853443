import { useState, useEffect } from "react";

export default function AdminCategories() {
    const [data, setData] = useState(false)
    const fetchData = async () => {
        try {
            const response = await fetch("https://goskilling-node-wju6y.ondigitalocean.app/opinex/categories/categories");
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const jsonData = await response.json();
            setData(jsonData);
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle the error
        }
    };

    useEffect(() => {
        fetchData();
    }, [])


    return <div className="bg-gray-950 mx-auto min-h-screen py-12 flex justify-center items-center">
        <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl pb-6">Categories</h2>
            <div className="max-w-2xl">
                {data ? <AdminInterests interestsdata={data} /> : "loading..."}
            </div>
        </div>
    </div>

}

function AdminInterests({ interestsdata }) {
    return (
        <ul role="list" className="divide-y divide-gray-100 text-gray-200">
            {interestsdata.map((person) => (
                <li key={person.category_name + person.parent_category} className="flex items-center justify-between gap-x-6 py-5">
                    <div className="flex gap-x-4">
                        <span className="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-500">
                            <span className="text-xl font-medium leading-none text-white">{person.category_id}</span>
                        </span>
                        <div className="min-w-0 flex-auto">
                            <p className="text-md font-semibold leading-6 text-gray-100">{person.category_name} </p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-400">{person.parent_category} - {person.icon +"-//-"+ person.parent_icon}</p>
                        </div>
                    </div>
                    <a
                        href={person.href}
                        className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        View
                    </a>
                </li>

            ))}
        </ul>
    )
}