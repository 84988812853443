import React, { useRef, useState } from 'react';
import AdminCategories from './admin.categories';
import AdminItems from './admin.items';

function UploadComponent() {
  const fileInput = useRef();

  // Set up state for form fields
  const [itemName, setItemName] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [dependentItem, setDependentItem] = useState("");
  const [authentication, setAuthentication] = useState("");

  const handleSubmit = async(e) => {
    e.preventDefault();

    if (!fileInput.current.files.length) {
      return alert('Please select a file');
    }

    var formdata = new FormData();
    formdata.append("file", fileInput.current.files[0]);
    formdata.append("itemName", itemName);
    formdata.append("categoryID", categoryID);
    formdata.append("dependentItem", dependentItem);
    formdata.append("authentication", authentication);
    console.log(formdata);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://goskilling-node-wju6y.ondigitalocean.app/opinex/items/item", requestOptions)
      .then(response => {
        console.log(response)
        response.text()
      })
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }

  return (
    <div className="bg-gray-800">
    <form onSubmit={handleSubmit} className="bg-gray-800 text-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4">
    <label className="block text-gray-100 text-sm font-bold mb-2">
      File:
      <input ref={fileInput} type="file" name="file" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:shadow-outline" />
    </label>

    <label className="block text-gray-100 text-sm font-bold mb-2">
      Item Name:
      <input value={itemName} onChange={e => setItemName(e.target.value)} type="text" name="itemName" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
    </label>

    <label className="block text-gray-100 text-sm font-bold mb-2">
      Category ID:
      <input value={categoryID} onChange={e => setCategoryID(e.target.value)} type="text" name="categoryID" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
    </label>

    <label className="block text-gray-100 text-sm font-bold mb-2">
      Dependent Item:
      <input value={dependentItem} onChange={e => setDependentItem(e.target.value)} type="text" name="dependentItem" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
    </label>

    <label className="block text-gray-100 text-sm font-bold mb-2">
      Stamp:
      <input value={authentication} onChange={e => setAuthentication(e.target.value)} type="text" name="authentication" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
    </label>

    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
      Submit
    </button>
  </form>
  <AdminCategories/>  
  <AdminItems/>  
    </div>

  );
}

export default UploadComponent;
