import React, { useState, useEffect,useRef } from 'react';
import Header from './pages/header';
import { ContainerN } from './components/containers';
import Card from './components/cards';
import Route from './components/route';
import About from './pages/about';
import Ranking from './pages/ranking';
import Categories from './pages/categories';
import UploadComponent from './pages/admin.upload';
// Import other necessary components and utilities

function App() {
  // State variables
  const [itemPair, setItemPair] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [itemA, setItemA] = useState(null);
  const [itemB, setItemB] = useState(null);
  const [rankingData, setRankingData] = useState(null);
  const [displaySelection, setDisplaySelection] = useState(null);
  const [elementList, setElemenetList] = useState([
  ]);
  const [rankingAllOrientation, setRankingAllOrientation] = useState("top");
  const [topElementList, setTopTelementList] = useState(false);
  const [bottomElementList, setBottomTelementList] = useState(false);
  const fetchedRef = useRef(false);
  // Other state variables as needed

  // Data fetching functions
  async function fetchItemPair() {
    if (fetchedRef.current) {
      return;
    }
    fetchedRef.current = true;
    console.log(Date.now());
    try {
      const response = await fetch("https://goskilling-node-wju6y.ondigitalocean.app/opinex/items/item_pairs");
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
  
      const a = {
        "item_id": jsonData.a_item_id,
        "name": jsonData.a_name,
        "dependent": jsonData.a_dependent,
        "category_id": jsonData.a_category_id,
        "image": jsonData.a_image,
        "category_name": jsonData.a_category_name,
        "icon": jsonData.a_icon,
      };
      const b = {
        "item_id": jsonData.b_item_id,
        "name": jsonData.b_name,
        "dependent": jsonData.b_dependent,
        "category_id": jsonData.b_category_id,
        "image": jsonData.b_image,
        "category_name": jsonData.b_category_name,
        "icon": jsonData.b_icon,
      };
      setItemA(a);
      setItemB(b);
      setItemPair({ a, b }); // Optionally, store the entire pair object
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally, handle the error in UI
    }
  }
  
  const submitRating = (winner, loser) => {

    var locStorage = localStorage.getItem("root");

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',"x-access-key": locStorage},
      body: JSON.stringify({
        //"winner":1,
        //"loser":2,
        "user": 1,
        "winner": [winner],
        "loser": [loser]
      })
    };
    fetch('https://goskilling-node-wju6y.ondigitalocean.app/opinex/ratings/ratings', requestOptions)
      .then(response => response.json())
  }

  async function fetchRankingData() {
    // Logic to fetch ranking data
    try {
      const response = await fetch("https://goskilling-node-wju6y.ondigitalocean.app/opinex/ratings/standingall");
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
      setElemenetList(jsonData);

      const response_top = await fetch("https://goskilling-node-wju6y.ondigitalocean.app/opinex/ratings/standingall/topten");
      if (!response_top.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData_top = await response_top.json();
      setTopTelementList(jsonData_top)

      const response_last = await fetch("https://goskilling-node-wju6y.ondigitalocean.app/opinex/ratings/standingall/lastten");
      if (!response_last.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData_last = await response_last.json();
      setBottomTelementList(jsonData_last)

    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error
    }
  }

  // Handle user interactions
  function handleImageClick(winner, loser) {
    // Logic for handling image clicks
    fetchedRef.current = false;
    setSelectedImage(winner);
    submitRating(winner, loser);
    setDisplaySelection(winner); // Set display selection
    //fetchStandingAll();
    //setCheckDone(false);
    setTimeout(() => {
      setSelectedImage(null);
    }, 1000)
    fetchItemPair();
    fetchRankingData();
  }

  // useEffect for initial data loading
  useEffect(() => {
    fetchItemPair();
    fetchRankingData();
    // Additional setup if needed
  }, []);

  // Render the component
  return (
    <div  className="bg-slate-800">
      <Header />
      <Route path="/">
      <div className="App bg-gray-950 min-h-screen">

      <ContainerN>
        <div className="container mx-auto py-8">
          {itemPair && !selectedImage && (
            <>
              <h1 className="text-3xl text-gray-100 font-bold mb-4 text-center">Which do you prefer?</h1>
              <div className="flex justify-center">
                <Card
                  imageSrc={itemA.image}
                  text={itemA.name}
                  supportText={itemA.icon + " " + itemA.category_name}
                  optionName={itemA.name}
                  identificator={itemA.name}
                  votePick={() => handleImageClick(itemA, itemB)}
                />
                <Card
                  imageSrc={itemB.image}
                  text={itemB.name}
                  supportText={itemB.icon + " " + itemB.category_name}
                  optionName={itemB.name}
                  identificator={itemB.name}
                  votePick={() => handleImageClick(itemB, itemA)}
                />
              </div>
            </>
          )}

          {selectedImage && (
            <div className="text-center">
              <h1 className='text-9xl'>🎉</h1>
              <p className="mt-4 text-gray-50">
                You have selected: {displaySelection.name}
              </p>
            </div>
          )}
        </div>
      </ContainerN>
      <ContainerN>
          <h1 className='text-3xl text-white'>General Ranking </h1>
          {
            topElementList && bottomElementList && elementList
            ? <div>
              <a type="button" className={rankingAllOrientation=="top"?"font-bold text-blue-500" : " text-blue-500"} onClick={() => setRankingAllOrientation("top")}>Top Ten</a>  
              <a className="my-3 text-white"> | </a>
              <a type="button"  className={!rankingAllOrientation=="bottom"?"font-bold text-blue-500" : " text-blue-500"} onClick={() => setRankingAllOrientation("bottom")}>Last Ten</a>
              <a className="my-3 text-white"> | </a>
              <a type="button"  className={!rankingAllOrientation=="all"?"font-bold text-blue-500" : " text-blue-500"} onClick={() => setRankingAllOrientation("all")}>All</a>
              {
                rankingAllOrientation=="top"
                  ? <Ranking dataInput={elementList.slice(0,10)} />
                  : rankingAllOrientation=="bottom" 
                  ? <Ranking dataInput={elementList.slice(-10)} />
                  : <Ranking dataInput={elementList} />
              }

            </div>
            : "loading"
          }

        </ContainerN>
      </div>
      </Route>
      <Route path="/about">
          <About/>
      </Route>
      <Route path="/categories">
          <Categories/>
      </Route>
      <Route path="/adminItem" >
          <ContainerN>
            <UploadComponent/>            
          </ContainerN>

      </Route>
      
    </div>
  );
}

export default App;
