export default function Ranking({dataInput}) {
  
    return <ul role="list" className="divide-y divide-gray-100 text-gray-100">
        {dataInput.map((item,index) => {

          let result = item.imageUrl.startsWith("https");
          return <li key={item.name+"_"+item.category_id+"_"+index} className="flex justify-between gap-x-6 py-5 hover:bg-slate-800">
          <div className="flex gap-x-4">
          <img className="h-12 w-12 flex-none rounded-full bg-gray-700 object-contain" src={result?item.imageUrl:"assets/logos/"+item.imageUrl} alt="" />
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-100">{item.name}</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-400">{item.category}</p>
            </div>
          </div>
          <div className="hidden sm:flex sm:flex-col sm:items-end">
            <p className="text-lg leading-6 text-gray-100">{item.score.toFixed(2)}</p>

              <p className="mt-1 text-xs leading-5 text-gray-400">
                 {item.category}
              </p>

          </div>
        </li>
        })}
    </ul>
}