import { useState, useEffect } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline"
import christian from "./../assets/company/christian.jpg";
const stats = [
    { label: 'Transactions every 24 hours', value: '44 million' },
    { label: 'Assets under holding', value: '$119 trillion' },
    { label: 'New users annually', value: '46,000' },
]

export default function About() {
    const [siteStats, setSiteStats] = useState(false);
    const fetchData = async () => {
        try {
            const response = await fetch("https://goskilling-node-wju6y.ondigitalocean.app/opinex/stats/stats");
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const jsonData = await response.json();
            setSiteStats(jsonData);
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle the error
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    return <div className="bg-gray-950 min-h-screen text-gray-200 -mt-12 py-5">
        <div className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:mt-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                <h2 className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">About Opininio</h2>
                <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                    <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                        <p className="text-xl leading-8 text-gray-300">
                            Welcome to Opininio, where we are revolutionizing the way brands and products are evaluated. Our mission is to empower consumers with data-driven insights, making informed choices accessible to everyone. Powered by EloRank, our platform brings objectivity and transparency to brand evaluations.                      </p>
                        <div className="mt-10 max-w-xl text-base leading-7 text-gray-300">
                            <p>
                                With Opininio, you can effortlessly compare brands, identify top performers, and uncover hidden gems across industries. Our dynamic rankings, updated in real-time, keep you informed about the ever-changing landscape. We believe in the power of progress, and our feedback-driven ecosystem encourages brands to continuously improve.                            </p>
                            <p className="mt-10">
                                Join us on this exciting journey and experience the thrill of engaging with our platform. Dive into the rankings, discover quality brands, and become part of a vibrant community that values informed decision-making. At Opininio, we are reshaping the way brands are evaluated, one data-driven choice at a time.                            </p>
                        </div>
                    </div>
                    <div className="lg:flex lg:flex-auto lg:justify-center">
                        <dl className="w-64 space-y-8 xl:w-80">
                            {siteStats ? siteStats.map((stat) => (
                                <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                                    <dt className="text-base leading-7 text-gray-400">{stat.label}</dt>
                                    <dd className="text-5xl font-semibold tracking-tight text-gray-100">{stat.value}</dd>
                                </div>
                            )) : ""}
                        </dl>
                    </div>
                </div>
            </div>

        </div>
        <div className="w-full">
            <Team />
        </div>
    </div>
}
const benefits = [
    'Exec. MBA (US), GPA 4.0, Georgia Tech OMSA student (since 2024)',
    'Product Manager since 2012',
    'Advanced Coding since 2016',
    'Passionated Rucker',
    '7 Publications (fiction & non-fiction)',
    'BA in Social Sciences',
]

function Team() {
    return (
        <div className="mt-4 sm:mt-12">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5  py-2 ring-1 ring-white/10 sm:rounded-3xl sm:p-2 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 ">
                    <img
                        className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                        src={christian}
                        alt=""
                    />
                    <div className="w-full flex-auto">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">Meet Christian</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Christian built Opininio in July between 12th and 18th of July 2023. Carrying around the idea for a tool to measure the popularity of brands with ratings, he built Opininio with the goal to further improve his front-end skills.
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            More about Christian:
                        </p>
                        <ul
                            role="list"
                            className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-gray-200 sm:grid-cols-2"
                        >
                            {benefits.map((benefit) => (
                                <li key={benefit} className="flex gap-x-3">
                                    <CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" />
                                    {benefit}
                                </li>
                            ))}
                        </ul>
                        <div className="mt-10 flex">
                            <a href="https://linkedin.com/in/krampchristian" className="text-sm font-semibold leading-6 text-blue-400">
                                Connect with me on Linkedin <span aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
