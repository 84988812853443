  export default function Interests({interestsdata}) {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {interestsdata.map((person) => (
          <div
            key={person.category_name+person.parent_category}
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-gray-950 hover:bg-gray-800 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
          >
            <div className="flex-shrink-0">
            {person.icon+person.parent_icon}
            </div>
            <div className="min-w-0 flex-1">
              <a href="#" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-100">{person.category_name}</p>
                <p className="truncate text-sm text-gray-200">{person.parent_category}</p>
              </a>
            </div>
          </div>
        ))}
      </div>
    )
  }